import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=ec701f90&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=ts&"
export * from "./detail.vue?vue&type=script&lang=ts&"
import style0 from "./detail.vue?vue&type=style&index=0&lang=less&"
import style1 from "./detail.vue?vue&type=style&index=1&id=ec701f90&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec701f90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaBox: require('/root/workspace/com.jufair.vue_Z6yh/components/MediaBox/index.vue').default,SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default})
