
















































































































































































































































































































































































































































































































import moment from 'moment';
import xss from 'xss';
import _ from 'lodash';
import Vue from 'vue';
// @ts-ignore
import vueQr from 'vue-qr';
import NewsType from './newsType';
import NewsItem from './component/NewsItem.vue';
import RelatedNew from './component/RelatedNew.vue';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import autoAddLink from '~/util/links';
import {
  decryptAndReplace,
  getContent,
  handleNewDetail,
  MINI_HOST,
  OSS_BUCKET_URL,
  removeEmptyTags,
  handleNewsUrl,
} from '~/util/common';
import FairTag from '~/components/Goods/fairTag.vue';
import MediaBox from '~/components/MediaBox/index.vue';
import ApplyBoothCard from '~/pages/booth/components/card.vue';
import { TraceCode } from '~/util/trace';
import { AxiosResponse } from 'axios';
import { blackList } from '~/util/blackFair';

export default Vue.extend({
  components: {
    vueQr,
    FairTag,
    MediaBox,
    NewsItem,
    ApplyBoothCard,
    RelatedNew,
  },
  filters: {
    lunchTime(data: any) {
      const startTime = moment(data.startTime).format('YYYY年MM月DD日');
      const endTime = moment(data.endTime).format('MM月DD日');

      return `${startTime}-${endTime}`;
    },
    description(item: Record<string, any>): any {
      const content = item.description || item.content;
      if (!_.isString(content)) return;

      const reg = /<[^>]+>/gi;
      let contentStr = content || '';
      const description =
        (contentStr ?? '') != ''
          ? contentStr
              ?.replace(reg, '')
              ?.replace(/&nbsp;/g, '')
              .trim()
          : '';
      return description?.slice(0, 100) + '...';
    },
  },
  async asyncData(ctx) {
    try {
      const { id } = ctx.params;

      const requestList = [
        ctx.$axios.post(`/api/newsApi/get`, { id }),
        // ctx.$axios.get(`/ossApi/InnerChainInfo/key.json`),
        ctx.$axios.get(`/ossApi/newsContent/${id}.html`),
      ];
      const [detailRes, bodyRes] = (await Promise.all(requestList)) as any[];
      // console.log('568-', typeof bodyRes != 'string');
      if (typeof bodyRes != 'string') {
        return ctx.error({
          message: '资讯不存在-oss返回错误',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
      let detail: Record<string, any> = {};
      //   console.log('576-', detailRes.data);
      //   console.log(
      //     '577-',
      //     !detailRes.data ||
      //       (detailRes.data.online != null && detailRes.data.online !== 2)
      //   );
      if (detailRes.code === ResponseCode.SUCCESS && detailRes.data) {
        // online: 1 未上架  2已上架
        if (
          !detailRes.data ||
          (detailRes.data.online != null && detailRes.data.online !== 2)
        ) {
          return ctx.error({
            message: 'Page not found-',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        // 图片懒加载(把图片的src改为data-src)
        // detailRes.data.content = typeof bodyRes == 'string' ? bodyRes.replace(/src=/gi, 'data-src=') : '';
        detailRes.data.content =
          typeof bodyRes == 'string' ? handleNewDetail(bodyRes) : '';
        detail = detailRes.data;
        detail.informationSecret = detailRes.informationSecret;
      } else {
        return ctx.error({
          message: '资讯不存在！',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }

      let fair = {};
      const news = [];
      let newNews = {};
      let tag = [];
      let bannerLits = [];
      let relatNew = [];
      if (!_.isEmpty(detail)) {
        const res: any[] = await Promise.all([
          ctx.$axios.post('/api/exhibition/list', {
            locale: detail.locale || 'cn',
            selectTimeType: 1,
            industryId: _.get(detail, 'industryId', null),
            pageNum: 1,
            pageSize: 12,
          }),
          ctx.$axios.post('/api/newsApi/homePageList', {
            industryId: _.get(detail, 'industryId', null),
            newsType: 1,
            pageNum: 1,
            pageSize: 8,
          }),
          ctx.$axios.post('/api/newsApi/homePageList', {
            newsType: 1,
            pageNum: 1,
            pageSize: 10,
          }),
          ctx.$axios.post('/api/specialTopicApi/resourceId', {
            type: 1,
            resourceId: id,
          }),
          ctx.$axios.post(`/api/newsApi/getNewsView/${id}`),
          ctx.$axios.post(`/api/news/get/related/${id}`),
        ]);
        const [fairRes, newsRes, newNewsRes, tagRes, viewRes, relatNewRed] =
          res;

        if (fairRes.code === ResponseCode.SUCCESS) {
          fair = fairRes.data;
        }

        if (
          newsRes.code === ResponseCode.SUCCESS &&
          newsRes.data &&
          newsRes.data.records
        ) {
          for (let i = 0; i < newsRes.data.records.length; i++) {
            const item = newsRes.data.records[i];
            news.push({
              newsId: item.newsId,
              viewUrl: item.viewUrl,
              newsName: item.newsName,
              logo: item.logo,
              createTime: moment(new Date(item.publishTime)).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              description:
                i === 0
                  ? await ctx.$axios.get(`/ossApi/newsContent/${item.id}.html`)
                  : '',
              views: item.viewCount,
            });
          }
        }

        // 最新资讯
        if (
          newNewsRes.code === ResponseCode.SUCCESS &&
          newNewsRes.data &&
          newNewsRes.data.records
        ) {
          newNews = newNewsRes.data.records?.map((item: any) => ({
            newsId: item.newsId,
            viewUrl: item.viewUrl,
            newsName: item.newsName,
            logo: item.logo,
            createTime: moment(new Date(item.publishTime)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
          }));
        }

        if (tagRes.code === ResponseCode.SUCCESS) {
          tag = tagRes.data;
        }

        if (viewRes.code === ResponseCode.SUCCESS) {
          detail.views = viewRes.data.viewCount;
        }
        //关联资讯
        if (relatNewRed.code === ResponseCode.SUCCESS) {
          relatNew = relatNewRed.data;
        }
      }

      let tagNews = [];
      if (tag && tag.length > 0) {
        const res = await Promise.all([
          // ctx.$axios.post(
          //   '/api/specialTopicApi/specialGetNewsByIds',
          //   tag?.map((t: any) => t.id)
          // ),
          ctx.$axios.post('/api/newsApi/homePageList', {
            industryId: _.get(detail, 'industryId', null),
            newsType: 1,
            pageNum: 2,
            pageSize: 12,
          }),
        ]);
        const [newsResLits]: AxiosResponse<any>[] = res;

        if (
          newsResLits.data.code === ResponseCode.SUCCESS &&
          newsResLits.data &&
          newsResLits.data.records
        ) {
          for (let i = 0; i < newsResLits.data.records.length; i++) {
            const item = newsResLits.data.records[i];
            tagNews.push({
              newsId: item.newsId,
              viewUrl: item.viewUrl,
              newsName: item.newsName,
              logo: item.logo,
              createTime: moment(new Date(item.publishTime)).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              description: await ctx.$axios.get(
                `/ossApi/newsContent/${item.id}.html`
              ),
              views: item.viewCount,
            });
          }
        }

        // const promises = newsRes.data?.map((item: any) => {
        //   return ctx.$axios.get(`/ossApi/newsContent/${item.id}.html`);
        // });

        // const contents = await Promise.all(promises || []);
        // tagNews = newsRes.data?.map((d: any, index: number) => ({
        //   newsId: d.id,
        //   logo: d.coverImg,
        //   newsName: d.title,
        //   industryId: [d.industryId],
        //   industryName: d.industryName,
        //   createTime: d.publishTime,
        //   views: d.viewCount,
        //   content: contents[index],
        // }));
      }

      let sourceResult =
        detail.exhibitionList && detail.exhibitionList.length > 0
          ? detail.exhibitionList[0]
          : null;
      // 把参考资料渲染到文章主体内容中
      let exhibitionHtmlStr = '';
      if (sourceResult) {
        exhibitionHtmlStr = `
            <p>参考资料：</p>
            <p style="margin:0"><a href="/exhibition/${sourceResult.id}.html" title="${sourceResult.exhibitionName}" style="color:#f00000;font-weight:bold;">
                    ${sourceResult.exhibitionName}
                </a></p>
            `;
        if (sourceResult.exhibitionEnglishName)
          exhibitionHtmlStr += `<p style="margin:0">${sourceResult.exhibitionEnglishName}</p>`;
        if (sourceResult.area)
          exhibitionHtmlStr += `<p style="margin:0">举办地区：${sourceResult.area}</p>`;
        if (sourceResult.launchTime)
          exhibitionHtmlStr += `<p style="margin:0">展会日期：${sourceResult.launchTime}</p>`;
        if (sourceResult.openTime)
          exhibitionHtmlStr += `<p style="margin:0">开闭馆时间：${sourceResult.openTime}</p>`;
        if (sourceResult.hostAddress)
          exhibitionHtmlStr += `<p style="margin:0">举办地址：${sourceResult.hostAddress}</p>`;
        if (sourceResult.exhibitionAddress)
          exhibitionHtmlStr += `<p style="margin:0">举办展馆：${sourceResult.exhibitionAddress}</p>`;
        if (sourceResult.scale)
          exhibitionHtmlStr += `<p style="margin:0">展览面积：${sourceResult.scale}</p>`;
        if (sourceResult.audience)
          exhibitionHtmlStr += `<p style="margin:0">观众数量：${sourceResult.audience}</p>`;
        if (sourceResult.cycle)
          exhibitionHtmlStr += `<p style="margin:0">举办周期：${sourceResult.cycle}</p>`;
        if (sourceResult.industryName)
          exhibitionHtmlStr += `<p style="margin:0">所属行业：${detail.industryName}</p>`;
        if (sourceResult.organizer)
          exhibitionHtmlStr += `<p style="margin:0">主办单位：${sourceResult.organizer}</p>`;
      }

      // 内链
      let innerChainActive: number = 0;
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          industryIdList: [detail.industryId],
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          industryIdList: [detail.industryId],
          pageSize: 16,
          // locale: detail.locale,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList: any[] = [];
      let innerChainIndustryList: any[] = [];
      let innerChainExhList: any[] = [];

      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
        let len = innerChainIndustryList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门行业',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainArea2Res] = ctxIndustry;
          if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
            innerChainIndustryList = innerChainIndustryList.concat(
              innerChainArea2Res.data.records
            );
          }
        }
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
        let len = innerChainExhList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门展会',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainExh2Res] = ctxIndustry;
          if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
            innerChainExhList = innerChainExhList.concat(
              innerChainExh2Res.data.records
            );
          }
        }
      }

      let sitemapList: any = [];
      // 获取所有大写字母
      for (var i = 65; i <= 90; i++) {
        sitemapList.push(String.fromCharCode(i));
      }

      return {
        detail,
        exhibitionHtmlStr,
        fair,
        news,
        newNews,
        tag,
        tagNews,
        relatNew,
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
        sitemapList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      detail: {},
      NewsType,
      MINI_HOST,
      OSS_BUCKET_URL,
      banner: [],
      dialogShow: false,
      dialogTitle: '',
      dialogImg: '',
      blackList,
    };
  },
  computed: {
    fairId(): number | undefined {
      const { exhibitionList } = this.detail;
      let id;
      if (!exhibitionList) return id;
      try {
        id = exhibitionList[0]?.id;
      } catch (error) {
        id = null;
        console.error(error);
      }
      return id;
    },
  },
  created() {
    this.getBanner();
    if (process.client) {
      this.detail.content = decryptAndReplace(
        this.detail.content,
        this.detail.informationSecret
      );
      // this.fetchLinks();
    }
  },
  mounted() {
    this.getAndCount();
  },
  methods: {
    xss,
    getContent,
    handleNewDetail,
    handleNewsUrl,
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    getAndCount() {
      this.$axios
        .post('/api/newsApi/getAndCount', {
          id: this.detail?.id,
        })
        .then((res: any) => {
          if (res.data?.viewCount) this.detail.views = res.data?.viewCount;
        });
    },
    async fetchLinks() {
      const { content, newsName } = this.detail;
      const linksRes = (await this.$axios.get(
        `/ossApi/InnerChainInfo/key.json`
      )) as any[];

      const html = removeEmptyTags(getContent(content));

      this.detail.content = await autoAddLink(html, linksRes, newsName);
    },
    handleShowMini() {
      this.dialogShow = true;
      this.dialogTitle = '扫码打开小程序';
      this.dialogImg = require('~/assets/images/juzhan-mini.jpg');
    },
    toNewDetail(url: string, id: number) {
      let str = handleNewsUrl(url, id);
      this.$windowOpen(str, '_blank');
    },
    async getBanner() {
      const res = await (<any>this.$axios.post(`/api/bannerApi/page`, {
        type: 1,
        channel: 0,
        industry2: this.detail.industryId,
        areaType: 0,
      }));
      if (res.code === ResponseCode.SUCCESS) {
        for (let i = 0; i < res.data.length; i++) {
          const url = res.data[i].bannerUrlList;
          const link = res.data[i].bannerLinkListPc;
          this.banner.push({
            url,
            link,
          });
        }
      }
    },
    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    // TODO 关注报错
    async handleAttention() {
      if (this.shouldLogin()) {
        const res: any = await this.$axios.get(
          `/api/news/attention/${this.$route.params.id}`
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.detail.focus = !this.detail.focus;
          if (this.detail.focus) {
            this.$message.success('关注成功.');
          } else {
            this.$message.success('取消关注成功.');
          }
        }
      }
    },
    // 分享到QQ空间
    shareToQzone(event: any) {
      event.preventDefault();
      const { newsName } = this.detail;
      let _shareUrl =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?';
      _shareUrl += 'url=' + encodeURIComponent(document.location.href); // 参数url设置分享的内容链接|默认当前页location
      _shareUrl += '&showcount=1'; // 参数showcount是否显示分享总数,显示：'1'，不显示：'0'，默认不显示
      _shareUrl +=
        '&desc=' +
        encodeURIComponent(
          this.detail.description ||
            '聚展网是国内专业国际展会一站式服务平台，帮助企业便捷参加全球展会，国际展会，企业首选的参展服务平台，公司已带领上千家企业出国参展，足迹遍布欧、美、亚、非、澳五大洲主流会展名城，为中国对外出口贸易做出重要贡献'
        ); // 参数desc设置分享的描述，可选参数
      _shareUrl +=
        '&summary=' +
        encodeURIComponent(
          newsName || '聚展网-展会网_国际展会_展会公司_国外展会信息服务平台'
        ); // 参数summary设置分享摘要，可选参数
      _shareUrl += '&title=' + encodeURIComponent(newsName || document.title); // 参数title设置分享标题，可选参数
      _shareUrl += '&site='; // 参数site设置分享来源，可选参数
      _shareUrl += '&pics=' + encodeURIComponent(''); // 参数pics设置分享图片的路径，多张图片以＂|＂隔开，可选参数
      window.open(
        _shareUrl,
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=800,height=600'
      );
    },
    // 分享到qq
    shareToqq(event: any) {
      event.preventDefault();
      let _shareUrl =
        'https://connect.qq.com/widget/shareqq/iframe_index.html?';
      _shareUrl += 'url=' + encodeURIComponent(document.location.href); // 分享的链接
      _shareUrl +=
        '&title=' + encodeURIComponent(this.detail.newsName || document.title); // 分享的标题
      window.open(
        _shareUrl,
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=800,height=600'
      );
    },
    async handleToPage(type: string) {
      if (type === 'booth') {
        await this.$store.commit('user/setAction', 1);
        window.open(`/exhibition/apply/${this.fairId}.html`, '_blank');
      } else if (type === 'ticket') {
        await this.$store.commit('user/setAction', 2);
        window.open(`/ticket/${this.fairId}.html`, '_blank');
      } else if (type === 'periodical') {
        await this.$store.commit('user/setAction', 3);
        window.open(`/periodical/${this.fairId}.html`, '_blank');
      }
    },
  },
  head(): any {
    const { detail } = this.$data;
    const reg = /<[^>]+>/gi;
    let description1 = _.get(detail, 'description', '');
    const _description1 = description1
      ? description1
          ?.replace(reg, '')
          ?.replace(/&nbsp;/g, '')
          .trim()
      : '';
    let description2 = _.get(detail, 'content', '');
    const _description2 = description2
      ? description2
          ?.replace(reg, '')
          ?.replace(/&nbsp;/g, '')
          .trim()
      : '';
    let keywords = ``;
    let firstExhibition = null;
    if (detail.exhibitionList && detail.exhibitionList.length > 0) {
      firstExhibition = detail.exhibitionList[0];
      keywords = `${firstExhibition.exhibitionName},${
        firstExhibition.exhibitionEnglishName
      },${firstExhibition.area || ''}${detail.industryName || ''}`;
    }
    let exStr =
      firstExhibition?.area && detail.industryName
        ? `${firstExhibition?.area || ''}${detail.industryName || ''}展`
        : '';

    return {
      title: `${detail.newsName}-聚展`, // `${detail.newsName}-${exStr}-聚展`
      meta: [
        {
          name: 'mobile-agent',
          'http-equiv': 'mobile-agent',
          content: `format=html5; url=https://m.jufair.com/information/${detail.id}.html`,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: _description1 || _description2, //.slice(0, 100),
        },
        {
          name: 'og:type',
          content: 'article',
        },
        {
          name: 'og:url',
          content: `https://www.jufair.com${this.$route.fullPath}`,
        },
        {
          name: 'og:title',
          content: detail.newsName,
        },
        {
          name: 'og:release_date',
          content: detail.createTime,
        },
        {
          name: 'og:image',
          content: detail.logo,
        },
        {
          name: 'og:description',
          content: _description1 || _description2, //_description.slice(0, 100),
        },
      ],
    };
  },
});
